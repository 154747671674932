import React from "react"
import { Container, Section } from "./Structure"
import ServicesBg from "./ServicesBg"
import Fade from "react-reveal/Fade"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import services from "../styles/services.module.css"
import footer from "../styles/footer.module.css"
import fonts from "../styles/fonts.module.css"

function Orders({ node }) {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_4]: (node, children) => (
        <div className={fonts.header}>
          <h4>{children}</h4>
        </div>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className={fonts.body}>
          <p>{children}</p>
        </div>
      ),
    },
  }

  return (
    <Section>
      <ServicesBg />
      <div className={footer.background} />

      <Container>
        <div id={"orders"} style={{ position: "absolute", top: "-3rem" }} />
        <div className={footer.wrapper}>
          <h2>{node.orders}</h2>

          {node.orderList.map((item, index) => (
            <Fade cascade>
              <div key={index}>
                {documentToReactComponents(item.orderDescription.json, options)}
                {item.orderAsset.map((asset, index) => (
                  <a href={asset.file.url}>Pobierz załącznik nr.{index+1}</a>
                ))}
              </div>
            </Fade>
          ))}
        </div>
      </Container>
    </Section>
  )
}

export default Orders
